import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button } from 'react-bootstrap'

const GetStartedPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Get Started" />
    <h1 style={styles.header}>Get Started</h1>
    <div style={styles.body}>
      <h3 style={{...styles.italic, ...styles.primaryText}}>Is Your Financial Advisor Doing Enough for You?</h3>
      <p>
        If not, it may be time for a change to the Coastal Advisory Group. As Independent Advisors we provide select 
        clients a highly personal style of investing that’s customized to who you are as a person and the goals you 
        have for your financial life.
      </p>
      <p>
        With us you’re not just part of the family, you’re part of the crew. Working together we can determine your 
        investments’ ultimate destination — including growing your wealth, how you live in retirement, and how you 
        transfer your wealth to family and your favorite charitable organizations.
      </p>
      <p>
        Our personal, hands-on approach to wealth management is designed to deliver better results by understanding 
        your unique needs.
      </p>
      <p style={styles.secondaryText}>
        To get started on your journey, call us today at <a href="tel:+15136191100">513-619-1100</a>
      </p>

      <div className="beach-couple-background">
        <div className="img--inner-content">
          <h5 style={{color: '#fff'}}>Click To Get Started On Your Journey</h5>
          <a href="tel:+15136191100">
            <Button className="main-btn">CLICK TO CALL</Button>
          </a>
        </div>
      </div>

    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default GetStartedPage
